import React, { useEffect, useState } from "react";
import {
  Palette,
  Eye,
  Store,
  Briefcase,
  ChevronRight,
  ChevronLeft,
  Check,
  ScissorsIcon,
  HandIcon,
  Ellipsis,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import { Skippblue } from "../../assets/images";
import { CleanHandsOutlined } from "@mui/icons-material";
import { apiClient } from "../../actions/api";

const PartnerSignup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    // Salon Data
    businessName: "",
    address: "",
    postalCode: "",
    city: "",
    email: "",
    phoneNumber: "",

    // User Data
    ownerFirstName: "",
    ownerLastName: "",
    ownerEmail: "",
    ownerPhone: "",
    ownerBirthdate: "",
    password: "",
    confirmPassword: "",

    // Additional Details
    services: [],
    businessType: "salon",
    termsAccepted: false,
  });

  useEffect(() => {
    console.log(formData)
  }, [formData])

  const services = [
    { id: 6, name: "Coiffure", icon: <ScissorsIcon className="w-10 h-10" /> },
    { id: 15, name: "Maquillage", icon: <Palette className="w-10 h-10" /> },
    { id: 4, name: "Manucure", icon: <HandIcon className="w-10 h-10" /> },
    {
      id: 11,
      name: "Massage",
      icon: <CleanHandsOutlined style={{ fontSize: "2.4rem" }} />,
    },
    { id: 32, name: "Extensions de Cils", icon: <Eye className="w-10 h-10" /> },
    {
      id: 0,
      name: "Autre",
      icon: <Ellipsis className="w-10 h-10" />,
    },
  ];

  const navigate = useNavigate();

  const nextStep = () => {
    switch (currentStep) {
      case 1:
        if (
          !formData.businessName ||
          !formData.address ||
          !formData.postalCode ||
          !formData.city ||
          !formData.email ||
          !formData.phoneNumber
        ) {
          toast.error("Veuillez remplir tous les champs obligatoires !");
          return;
        }
        setCurrentStep(Math.min(currentStep + 1, 4));
        break;
      case 2:
        if (formData.services.length === 0) {
          toast.error("Veuillez sélectionner au moins un service !");
          return;
        }
        setCurrentStep(Math.min(currentStep + 1, 4));
        break;
      case 3:
        if (
          !formData.ownerFirstName ||
          !formData.ownerLastName ||
          !formData.ownerEmail ||
          !formData.password ||
          !formData.confirmPassword
        ) {
          toast.error("Veuillez remplir tous les champs obligatoires !");
          return;
        }
        if (formData.password !== formData.confirmPassword) {
          toast.error("Les mots de passe ne correspondent pas !");
          return;
        }
        setCurrentStep(Math.min(currentStep + 1, 4));
        break;
    }
  };

  const prevStep = () => {
    setCurrentStep(Math.max(currentStep - 1, 1));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const toggleService = (serviceId) => {
    setFormData((prev) => {
      // If selecting "Autre" (id: 0)
      if (serviceId === 0) {
        return {
          ...prev,
          services: prev.services.includes(0) 
            ? prev.services.filter(id => id !== 0) 
            : [0]
        };
      }
  
      // If selecting a specific service while "Autre" is present
      if (prev.services.includes(0)) {
        return {
          ...prev,
          services: [serviceId]
        };
      }
  
      // Normal service selection logic
      return {
        ...prev,
        services: prev.services.includes(serviceId)
          ? prev.services.filter((id) => id !== serviceId)
          : [...prev.services, serviceId]
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.termsAccepted) {
      toast.error("Vous devez accepter les conditions d'utilisation");
      return;
    }

    // Show loading toast
    const loadingToast = toast.loading("Traitement de votre demande...");

    // Prepare data for API
    const salonData = {
      name: formData.businessName,
      email: formData.email,
      phone_number: formData.phoneNumber,
      city: formData.city,
      address: formData.address,
      services: formData.services
    };

    const userData = {
      first_name: formData.ownerFirstName,
      last_name: formData.ownerLastName,
      email: formData.ownerEmail,
      phone: formData.ownerPhone,
      birthday: formData.ownerBirthdate,
      password: formData.password,
      code_postal: formData.postalCode,
    };

    try {
      const userResponse = await apiClient().post(`/users`, { ...userData, role: "admin", status: false });
      
      if (userResponse?.status === 201) {
        const salonResponse = await apiClient().post(`/salons`, { ...salonData, status: false, user_id: userResponse.data?.id });
        
        if (salonResponse.status === 201) {
          // Dismiss loading toast and show success
          toast.dismiss(loadingToast);
          toast.success(
            "Votre demande a été envoyée avec succès. Un e-mail vous sera envoyé une fois votre demande traitée.",
            { duration: 4000 }
          );

          // Reset form and navigate
          setFormData({
            businessName: "",
            address: "",
            postalCode: "",
            city: "",
            email: "",
            phoneNumber: "",
            ownerFirstName: "",
            ownerLastName: "",
            ownerEmail: "",
            ownerPhone: "",
            ownerBirthdate: "",
            password: "",
            confirmPassword: "",
            services: [],
            businessType: "salon",
            termsAccepted: false,
          });

          // Redirect after a short delay
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.dismiss(loadingToast);
          toast.error("Une erreur est survenue lors de la création du salon.");
        }
      } else {
        toast.dismiss(loadingToast);
        toast.error("Une erreur est survenue lors de la création de l'utilisateur.");
      }
    } catch (err) {
      console.error(err);
      toast.dismiss(loadingToast);
      toast.error("Une erreur est survenue lors de l'envoi de la demande.");
    }
  };

  // Render methods remain the same as in the previous component
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-6 p-4 sm:p-8">
            <div className="text-center space-y-6">
              <div
                className="flex justify-center items-center cursor-pointer"
                onClick={() => navigate("/entreprise")}
              >
                <img
                  src={Skippblue}
                  alt="Skipp Logo"
                  className="h-8 sm:h-10 mr-2"
                />
                <span className="text-lg sm:text-2xl font-bold text-[#2548FF]">
                  Business
                </span>
              </div>
              <h1 className="text-3xl font-bold text-gray-800">
                Bienvenue chez Skipp Partenaire
              </h1>
              <p className="text-gray-600 text-lg">
                Rejoignez notre réseau et développez votre entreprise avec Skipp
              </p>
            </div>
            <div className="space-y-6">
              <h2 className="text-xl font-bold text-gray-800">
                Informations sur votre établissement
              </h2>
              <div className="grid md:grid-cols-2 gap-4">
                <input
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                  placeholder="Nom de l'Institut"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                  required
                />
                <input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Adresse e-mail de l'Institut"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                  required
                />
                <input
                  name="phoneNumber"
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Numéro de téléphone de l'Institut"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                />
                <input
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Adresse"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                  required
                />
                <input
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                  placeholder="Code Postal"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                />
                <input
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="Ville"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                  required
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-6 p-4 sm:p-8">
            <h2 className="text-2xl font-bold text-gray-800">
              Sélectionnez vos services
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {services.map((service) => (
                <button
                  key={service.id}
                  type="button"
                  onClick={() => toggleService(service.id)}
                  className={`flex flex-col items-center p-4 rounded-lg border-2 transition-all ${
                    formData.services.includes(service.id)
                      ? "bg-[#2548FF] text-white border-[#2548FF]"
                      : "border-gray-200 hover:bg-gray-50"
                  }`}
                >
                  {service.name === "Massage" ? (
                    <CleanHandsOutlined
                      style={{
                        fontSize: "2.4rem",
                        color: formData.services.includes(service.id)
                          ? "white"
                          : "#2548FF",
                        marginBottom: "0.5rem",
                      }}
                    />
                  ) : service.icon ? (
                    React.isValidElement(service.icon) ? (
                      React.cloneElement(service.icon, {
                        className: `w-10 h-10 mb-2 ${
                          formData.services.includes(service.id)
                            ? "text-white"
                            : "text-[#2548FF]"
                        }`,
                      })
                    ) : (
                      <span className="w-10 h-10 mb-2 text-[#2548FF]">
                        {/* Fallback if icon is not a valid React element */}
                        Icon
                      </span>
                    )
                  ) : (
                    <span className="w-10 h-10 mb-2 text-[#2548FF]">
                      {/* Fallback if no icon provided */}
                      Default Icon
                    </span>
                  )}
                  <span className="text-sm mt-2">{service.name}</span>
                </button>
              ))}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-6 p-4 sm:p-8">
            <h2 className="text-2xl font-bold text-gray-800">
              Informations personnelles
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              <input
                name="ownerFirstName"
                value={formData.ownerFirstName}
                onChange={handleChange}
                placeholder="Prénom *"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                required
              />
              <input
                name="ownerLastName"
                value={formData.ownerLastName}
                onChange={handleChange}
                placeholder="Nom *"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                required
              />
              <input
                name="ownerEmail"
                type="email"
                value={formData.ownerEmail}
                onChange={handleChange}
                placeholder="Adresse e-mail *"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                required
              />
              <input
                name="ownerPhone"
                type="tel"
                value={formData.ownerPhone}
                onChange={handleChange}
                placeholder="Numéro de téléphone"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
              />

              <div className="md:col-span-2 grid md:grid-cols-1 gap-4">
                <input
                  name="ownerBirthdate"
                  type="date"
                  value={formData.ownerBirthdate}
                  onChange={handleChange}
                  placeholder="Date de Naissance"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                />
              </div>
              <div className="md:col-span-2 grid md:grid-cols-2 gap-4">
                <input
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Mot de passe *"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                  required
                />
                <input
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirmer le mot de passe *"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                  required
                />
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-6 p-4 sm:p-8">
            <h2 className="text-2xl font-bold text-gray-800">
              Type d'établissement
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() =>
                  setFormData((prev) => ({ ...prev, businessType: "salon" }))
                }
                className={`flex items-center justify-center gap-4 p-6 border-2 rounded-lg transition-all ${
                  formData.businessType === "salon"
                    ? "bg-[#2548FF] text-white border-[#2548FF]"
                    : "border-gray-200 hover:bg-gray-50"
                }`}
              >
                <Store
                  className={`w-8 h-8 ${
                    formData.businessType === "salon"
                      ? "text-white"
                      : "text-[#2548FF]"
                  }`}
                />
                <span className="text-lg">Salon</span>
              </button>
              <button
                type="button"
                onClick={() => toast.error("Ce type de salon sera disponible dans la nouvelle version !")}
                className={`flex items-center justify-center gap-4 p-6 border-2 rounded-lg transition-all ${
                  formData.businessType === "home"
                    ? "bg-[#2548FF] text-white border-[#2548FF]"
                    : "border-gray-200 hover:bg-gray-50"
                }`}
              >
                <Briefcase
                  className={`w-8 h-8 ${
                    formData.businessType === "home"
                      ? "text-white"
                      : "text-[#2548FF]"
                  }`}
                />
                <span className="text-lg">À domicile</span>
              </button>
            </div>
            <div className="space-y-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.termsAccepted}
                  onChange={() =>
                    setFormData((prev) => ({
                      ...prev,
                      termsAccepted: !prev.termsAccepted,
                    }))
                  }
                  className="mr-2 accent-[#2548FF]"
                />
                <label className="text-sm">
                  J'accepte les{" "}
                  <a
                    href="/conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#2548FF] underline"
                  >
                    conditions générales
                  </a>{" "}
                  et la{" "}
                  <a href="/politique" className="text-[#2548FF] underline">
                    politique de confidentialité
                  </a>
                </label>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <header className="fixed top-0 left-0 right-0 z-20 bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center h-16">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => navigate("/entreprise")}
          >
            <img
              src={Skippblue}
              alt="Skipp Logo"
              className="h-8 sm:h-10 mr-2"
            />
            <span className="text-lg sm:text-2xl font-bold text-[#2548FF]">
              Business
            </span>
          </div>
        </div>
      </header>
      <div className="fixed top-16 left-0 right-0 z-20 bg-white">
        <div className="w-full max-w-2xl mx-auto py-2">
          <div className="w-full flex">
            {[1, 2, 3, 4].map((step) => (
              <div
                key={step}
                className={`flex-1 h-1.5 rounded-full transition-colors mx-1 ${
                  currentStep >= step ? "bg-[#2548FF]" : "bg-gray-300"
                }`}
              />
            ))}
          </div>
        </div>
      </div>

      <Toaster position="top-right" />
      <div className="w-full max-w-2xl overflow-hidden relative min-h-screen mt-16">
        <div className="p-4 sm:p-12 pb-24">
          <form onSubmit={handleSubmit}>
            {renderStep()}
            <div className="h-20"></div>
            <div className="fixed bottom-0 left-0 right-0 p-4 bg-white/90 backdrop-blur-sm shadow-top z-30">
              <div className="w-full mx-auto flex justify-between">
                {currentStep > 1 && (
                  <button
                    type="button"
                    onClick={prevStep}
                    className="flex items-center gap-2 text-[#2548FF] hover:bg-blue-50 px-4 sm:px-6 py-2 rounded-full transition"
                  >
                    <ChevronLeft className="w-5 h-5" /> Précédent
                  </button>
                )}
                {currentStep < 4 && (
                  <button
                    type="button"
                    onClick={nextStep}
                    className="ml-auto flex items-center gap-2 bg-[#2548FF] text-white px-4 sm:px-6 py-2 rounded-full hover:bg-blue-700 transition"
                  >
                    Suivant <ChevronRight className="w-5 h-5" />
                  </button>
                )}
                {currentStep === 4 && (
                  <button
                    type="submit"
                    disabled={!formData.termsAccepted}
                    className="ml-auto flex items-center gap-2 bg-[#2548FF] text-white px-4 sm:px-6 py-2 rounded-full hover:bg-blue-700 transition disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <Check className="w-5 h-5" /> Finaliser l'inscription
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PartnerSignup;

import React, { useState, useEffect } from "react";
import AppleIcon from "@mui/icons-material/Apple";
import ShopIcon from "@mui/icons-material/Shop";
import CloseIcon from "@mui/icons-material/Close";

// Popup Component
const DownloadAppPopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const checkModalVisibility = () => {
      const lastShown = localStorage.getItem("appModalLastShown");
      const now = new Date().getTime();
      const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;

      if (!lastShown || now - parseInt(lastShown) > sevenDaysInMs) {
        setIsVisible(true);
        localStorage.setItem("appModalLastShown", now.toString());
      }
    };

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        checkModalVisibility();
      } else {
        setIsVisible(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const closeModal = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-800">Téléchargez Skipp</h2>
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700"
          >
            <CloseIcon size={24} />
          </button>
        </div>
        <p className="text-gray-600 mb-6">
          Profitez d'une expérience optimisée avec Skipp et restez connecté où
          que vous soyez. Téléchargez notre application dès maintenant !
        </p>
        <div className="space-y-3">
          <a
            href="#"
            className="flex items-center justify-center w-full bg-[#f79d68] hover:bg-[#f58d4f] text-white font-semibold py-2 px-4 rounded text-center transition duration-300"
          >
            <AppleIcon className="mr-2" size={20} />
            Télécharger sur l'App Store
          </a>
          <a
            href="#"
            className="flex items-center justify-center w-full bg-[#f79d68] hover:bg-[#f58d4f] text-white font-semibold py-2 px-4 rounded text-center transition duration-300"
          >
            <ShopIcon className="mr-2" size={20} />
            Obtenir sur Google Play
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppPopup;

import React, { useState, useRef, useEffect } from "react";
import {
  Play,
  Users,
  CheckCircle,
  BookOpen,
  MessageCircle,
  Star,
  Award,
  Target,
  Layers,
  Menu,
  X,
  Quote,
  Book,
  Calendar,
  Mail,
} from "lucide-react";
import {
  SkippBannerBus,
  SkippAboutUs,
  Skippblue,
  Skippwhite,
  SkippRevSection,
  SkippFeatSection,
} from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { Chat, Support } from "@mui/icons-material";

const SalonPartnerLanding = () => {
  const [activeVideo, setActiveVideo] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const features = [
    {
      icon: <BookOpen className="w-12 h-12 text-[#2548FF]" />,
      title: "Gestion de réservations",
      description:
        "Simplifiez votre planning et optimisez vos rendez-vous en quelques clics.",
    },
    {
      icon: <Users className="w-12 h-12 text-[#2548FF]" />,
      title: "Audience élargie",
      description:
        "Connectez-vous avec plus de clients et développez votre entreprise.",
    },
    {
      icon: <MessageCircle className="w-12 h-12 text-[#2548FF]" />,
      title: "Communication facilitée",
      description:
        "Gérez les communications clients de manière transparente et efficace.",
    },
  ];

  const reviews = [
    {
      name: "Sophie Dupont",
      role: "Propriétaire de salon",
      quote:
        "Skipp a complètement transformé la gestion de mon salon. Mes réservations ont augmenté de 40% !",
      rating: 5,
      image: "/happy-salon-owner.png",
    },
    {
      name: "Marie Laurent",
      role: "Coiffeuse indépendante",
      quote:
        "Une plateforme intuitive qui me permet de me concentrer sur mon métier plutôt que sur la paperasserie.",
      rating: 5,
      image: "/another-salon-owner.png",
    },
  ];

  const features2 = [
    {
      title: "Gestion centralisée",
      description:
        "Gérez tous vos rendez-vous, clients, et ressources depuis une interface intuitive, accessible sur tous vos appareils.",
      icon: <Calendar />, // Replace with your icon component/path
    },
    {
      title: "Ressources et guides",
      description:
        "Accédez instantanément à des conseils, guides, et formations pour maîtriser toutes les fonctionnalités du logiciel.",
      icon: <Book />, // Replace with your icon component/path
    },
    {
      title: "Support instantané",
      description:
        "Contactez notre support via le chat intégré pour des réponses en moins de 5 minutes, 7j/7.",
      icon: <Mail />, // Replace with your icon component/path
    },
  ];
  

  const [currentReview, setCurrentReview] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((prev) => (prev + 1) % reviews.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [reviews.length]);

  return (
    <div className="bg-white">
      {/* Header with Mobile Responsiveness */}
      <header
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled ? "bg-white shadow-sm" : "bg-transparent"
        }`}
      >
        <div className="container mx-auto px-4 sm:px-6 py-4 flex justify-between items-center">
          {/* Logo Section */}
          <div className="flex items-center">
            <img
              src={isScrolled ? Skippblue : Skippwhite}
              alt="Skipp Logo"
              className="h-8 sm:h-10 mr-2"
            />
            <span
              className={`text-lg sm:text-2xl font-bold transition-all ${
                isScrolled ? "text-[#2548FF]" : "text-white"
              }`}
            >
              Business
            </span>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex ml-6 sm:ml-12 space-x-4 sm:space-x-6">
            <a
              href="#advantages"
              className={`text-sm sm:text-base transition ${
                isScrolled
                  ? "text-gray-700 hover:text-[#2548FF]"
                  : "text-white hover:text-[#2548FF]"
              }`}
            >
              Nos Avantages
            </a>
            <a
              href="#about"
              className={`text-sm sm:text-base transition ${
                isScrolled
                  ? "text-gray-700 hover:text-[#2548FF]"
                  : "text-white hover:text-[#2548FF]"
              }`}
            >
              À propos
            </a>
            <a
              href="#testimonials"
              className={`text-sm sm:text-base transition ${
                isScrolled
                  ? "text-gray-700 hover:text-[#2548FF]"
                  : "text-white hover:text-[#2548FF]"
              }`}
            >
              Témoignages
            </a>
            <a
              href="#features"
              className={`text-sm sm:text-base transition ${
                isScrolled
                  ? "text-gray-700 hover:text-[#2548FF]"
                  : "text-white hover:text-[#2548FF]"
              }`}
            >
              Fonctionnalités
            </a>
          </nav>

          {/* Desktop Buttons */}
          <div className="hidden md:flex items-center space-x-4">
            <a
              href="https://admin.skipp.ma"
              className={`px-4 py-2 rounded-full transition text-sm ${
                isScrolled
                  ? "text-[#2548FF] hover:bg-[#2548FF]/10"
                  : "text-white hover:bg-white/10"
              }`}
            >
              Se connecter
            </a>
            <button
              className={`px-6 font-bold py-2 rounded-full transition shadow-md hover:shadow-lg text-sm ${
                isScrolled
                  ? "bg-[#2548FF] text-white hover:bg-blue-700"
                  : "bg-white text-[#2548FF] hover:bg-gray-300"
              }`}
              onClick={() => navigate("/entreprise/signup")}
            >
              Commencer Maintenant
            </button>
          </div>

          {/* Mobile Menu Toggle */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className={`focus:outline-none ${
                isScrolled ? "text-[#2548FF]" : "text-white"
              }`}
            >
              {isMobileMenuOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden absolute top-full left-0 right-0 bg-white shadow-lg">
            <nav className="flex flex-col p-4 space-y-4">
              <a
                href="#advantages"
                className="text-gray-700 hover:text-[#2548FF] transition"
              >
                Nos Avantages
              </a>
              <a
                href="#about"
                className="text-gray-700 hover:text-[#2548FF] transition"
              >
                À propos
              </a>
              
              <a
                href="#testimonials"
                className="text-gray-700 hover:text-[#2548FF] transition"
              >
                Témoignages
              </a>
              <a
                href="#features"
                className="text-gray-700 hover:text-[#2548FF] transition"
              >
                Fonctionnalités
              </a>
              
              <div className="flex flex-col space-y-2 pt-2 border-t">
                <button className="text-[#2548FF] hover:bg-[#2548FF]/10 px-4 py-2 rounded-full transition">
                  Se connecter
                </button>
                <button onClick={() => navigate("/entreprise/signup")} className="bg-[#2548FF] text-white px-6 py-2 rounded-full hover:bg-blue-700 transition">
                  Commencer
                </button>
              </div>
            </nav>
          </div>
        )}
      </header>

      {/* Banner Section with Responsiveness */}
      <section
        className="bg-[#2548FF] text-white pt-44 min-h-screen px-4 sm:px-8"
        style={{
          backgroundImage: `url(${SkippBannerBus})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 md:pr-12 text-left md:text-left mb-8 md:mb-0">
            <h1 className="text-3xl sm:text-4xl md:text-5xl text-[#FFDA18] font-bold mb-4 sm:mb-6">
              Révolutionnez votre salon de beauté
            </h1>
            <p className="text-base sm:text-xl mb-6 sm:mb-8">
              Rejoignez Skipp et transformez la gestion de votre entreprise avec
              notre plateforme innovante de réservation.
            </p>
            <div className="flex flex-col sm:flex-row justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
              <button
                className="bg-white text-[#2548FF] px-6 sm:px-8 py-2 sm:py-3 rounded-full hover:bg-[#FFDA18] transition flex items-center justify-center shadow-md"
                onClick={() => setActiveVideo(true)}
              >
                <Play className="mr-2 w-4 h-4 sm:w-5 sm:h-5" /> Voir comment ça
                marche
              </button>
              <button
                onClick={() => navigate("/entreprise/signup")}
                className="border-2 border-white text-white px-6 sm:px-8 py-2 sm:py-3 rounded-full hover:bg-[#FFDA18] hover:text-[#2548FF] transition flex items-center justify-center"
              >
                Devenir Partenaire
              </button>
            </div>
          </div>
          <div className="w-full md:w-1/2 mt-8 md:mt-0">
            <div
              className={`bg-gray-800 h-48 sm:h-[300px] md:h-[400px] flex items-center justify-center rounded-lg shadow-2xl transition-transform duration-300 ${
                activeVideo ? "transform scale-100" : "transform scale-0"
              }`}
            >
              {activeVideo && (
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-lg"
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </section>
      <div id="advantages" className="h-10 bg-gray-50"></div>
      <section className="bg-gradient-to-br from-blue-50 to-blue-100 py-20 px-6 sm:px-10">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-extrabold text-[#1A73E8] mb-4">
            Découvrez Nos Avantages
          </h2>
          <p className="text-lg text-gray-700 mb-12">
            Une expérience enrichie avec des fonctionnalités améliorées.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-xl shadow-lg transform hover:scale-105 transition-transform"
              >
                <div className="mb-6 flex justify-center text-[#1A73E8] text-3xl">
                  {feature.icon}
                </div>
                <h3 className="text-2xl font-bold text-gray-800 mb-4">
                  {feature.title}
                </h3>
                <p className="text-base text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* About Us Section with Responsiveness */}
      <section id="about" className="container mx-auto py-16 px-4 sm:px-8">
        <div className="flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 md:pr-12 mb-8 md:mb-0">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6 text-[#2548FF] text-center md:text-left">
              Notre Mission
            </h2>
            <p className="text-base sm:text-xl text-gray-700 mb-6 text-center md:text-left">
              Skipp est né de la passion de simplifier la gestion des salons de
              beauté. Nous croyons que les professionnels du bien-être méritent
              des outils technologiques qui leur permettent de se concentrer sur
              ce qu'ils font le mieux : prendre soin de leurs clients.
            </p>
            <div className="grid grid-cols-2 gap-4">
              {[
                {
                  icon: (
                    <Target className="text-[#2548FF] mr-3 w-8 sm:w-10 h-8 sm:h-10" />
                  ),
                  text: "Vision claire",
                },
                {
                  icon: (
                    <Award className="text-[#2548FF] mr-3 w-8 sm:w-10 h-8 sm:h-10" />
                  ),
                  text: "Innovation constante",
                },
                {
                  icon: (
                    <Layers className="text-[#2548FF] mr-3 w-8 sm:w-10 h-8 sm:h-10" />
                  ),
                  text: "Solution complète",
                },
                {
                  icon: (
                    <CheckCircle className="text-[#2548FF] mr-3 w-8 sm:w-10 h-8 sm:h-10" />
                  ),
                  text: "Satisfaction garantie",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center md:justify-start"
                >
                  {item.icon}
                  <span className="font-semibold text-sm sm:text-base">
                    {item.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src={SkippAboutUs}
              style={{ objectFit: "cover", objectPosition: "center" }}
              alt="Skipp Team"
              className="rounded-lg shadow-lg w-full"
            />
          </div>
        </div>
        <div className="flex flex-col mt-10 sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
          <button
            onClick={() => navigate("/entreprise/signup")}
            className="text-white bg-[#2548FF] px-8 sm:px-12 py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-[#FFDA18] transition"
          >
            Devenir Partenaire
          </button>
        </div>
      </section>

      <div id="testimonials" className="h-10"></div>
      {/* Reviews Section with Responsiveness */}
      <section className="container mx-auto py-16 px-4 sm:px-8">
        {/* Title */}
        <h2 className="text-3xl sm:text-4xl font-bold mb-8 sm:mb-12 text-[#2548FF] text-center">
          Nos partenaires témoignent
        </h2>

        {/* Content Wrapper */}
        <div className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-12">
          {/* Image Section */}
          <div className="w-full md:w-1/2 max-w-md">
            <div className="relative">
              <img
                src={SkippRevSection}
                alt="Salon Owner"
                className="rounded-lg shadow-lg w-full object-cover aspect-square"
              />
              <div className="absolute flex bottom-4 left-4 p-2">
                <Quote className="text-[#2548FF] w-9 h-9" />
                <Quote className="ml-[-31px] mt-[2px] text-[#FFDA18] w-8 h-8" />
              </div>
            </div>
          </div>

          {/* Reviews Section */}
          <div className="w-full md:w-1/2 max-w-md">
            <div
              ref={carouselRef}
              className={`${
                window.innerWidth < 768
                  ? "bg-gray-50 p-6 sm:p-8 rounded-lg shadow-md relative overflow-hidden"
                  : "space-y-6"
              }`}
            >
              {reviews.map((review, index) => (
                <div
                  key={index}
                  className={`transition-all duration-500 ease-in-out ${
                    window.innerWidth < 768
                      ? index === currentReview
                        ? "opacity-100 translate-x-0"
                        : "absolute top-0 left-0 opacity-0 translate-x-full"
                      : "flex flex-col items-center bg-gray-100 p-4 sm:p-6 rounded-lg shadow-md"
                  }`}
                >
                  {/* Star Ratings */}
                  <div className="flex justify-center mb-4">
                    <div className="flex text-[#FFDA18]">
                      {[...Array(review.rating)].map((_, i) => (
                        <Star key={i} className="w-6 h-6 fill-current" />
                      ))}
                    </div>
                  </div>

                  {/* Reviewer Info */}
                  <h3 className="font-bold text-xl text-center mb-2 text-[#2548FF]">
                    {review.name}
                  </h3>
                  <p className="text-gray-700 italic text-center mb-2">
                    "{review.quote}"
                  </p>
                  <p className="text-gray-500 text-center text-sm">
                    {review.role}
                  </p>
                </div>
              ))}

              {/* Dot Indicators for Carousel */}
              {window.innerWidth < 768 && (
                <div className="flex justify-center mt-6 space-x-2">
                  {reviews.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentReview(index)}
                      className={`w-2 h-2 rounded-full transition-all duration-300 ${
                        index === currentReview
                          ? "bg-[#2548FF] w-6"
                          : "bg-gray-300"
                      }`}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <div className="h-10" id="features"></div>
      {/* Features Section with Responsiveness */}
      <section
        style={{
          backgroundImage: `linear-gradient(
            rgba(0, 0, 0, 0.6), 
            rgba(0, 0, 0, 0.6)
          ), url(${SkippFeatSection})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="block-three-features bg-gray-200 py-16 px-6 sm:px-12 lg:px-24 m-6 rounded-xl mb-20"
      >
        <div className="mx-auto flex flex-col lg:flex-row items-center">
          {/* Left Side: Text Content */}
          <div className="lg:w-2/3 space-y-6 mb-8 lg:mb-0">
            <h2 className="text-5xl font-bold text-[#FFDA18]">
              Bien plus qu’un logiciel de gestion
            </h2>
            <p className="text-lg text-white max-w-[600px]">
              Nous sommes votre partenaire. Notre équipe d’experts est à vos
              côtés pour vous aider à développer votre activité. Nos différentes
              ressources vous aident à tirer parti de Skipp.
            </p>
            <button onClick={() => navigate("/entreprise/signup")} className="bg-[#2548FF] text-white py-3 px-6 rounded-lg font-medium hover:bg-blue-500 transition">
              Commencer gratuitement
            </button>
          </div>

          {/* Right Side: Features */}
          <div className="lg:w-1/2 flex flex-col space-y-6">
            {features2.map((feature, index) => (
              <div
                key={index}
                className={`flex gap-3 flex-col bg-white shadow-lg rounded-lg px-6 py-4 ${
                  index % 2 === 0 ? "lg:ml-10" : "lg:mr-10"
                }`} // Moves items to the left or right based on index
              >
                <div className="flex items-center gap-2">
                  {/* Feature Icon */}
                  <div className="flex justify-center text-[#FFDA18] text-4xl">
                    {feature.icon}
                  </div>
                  {/* Feature Content */}
                  <div>
                    <h3 className="text-lg font-semibold text-[#2548FF]">
                      {feature.title}
                    </h3>
                  </div>
                </div>
                <p className="text-sm text-gray-600">
                      {feature.description}
                    </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action with Responsiveness */}
      <section className="bg-[#2548FF] text-white py-16 sm:py-24 text-center px-4 sm:px-8">
        <h2 className="text-3xl sm:text-5xl font-bold mb-4 sm:mb-6">
          Envie de rejoindre l'expérience Skipp ?
        </h2>
        <p className="text-base sm:text-xl mb-6 sm:mb-10">
          Réussissez à nos côtés. Commencez dès maintenant et transformez votre
          salon.
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
          <button
            onClick={() => navigate("/entreprise/signup")}
            className="bg-white text-[#2548FF] px-8 sm:px-12 py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-[#FFDA18] transition"
          >
            Devenir Partenaire
          </button>
          <button className="border-2 border-white text-white px-8 sm:px-12 py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-[#FFDA18] hover:text-[#2548FF] transition">
            En savoir plus
          </button>
        </div>
      </section>

      {/* Footer with Responsive Design */}
      <footer className="bg-gray-900 text-white py-8 sm:py-12 px-4 sm:px-8">
        <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center space-y-6 sm:space-y-0">
          <div className="text-center sm:text-left">
            <img
              src={Skippblue}
              alt="Skipp Logo"
              className="h-8 sm:h-10 mx-auto sm:mx-0 mb-4"
            />
            <p className="text-xs sm:text-sm text-gray-400 text-center sm:text-left">
              © 2024 Skipp. Tous droits réservés.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6">
            <a
              href="#"
              className="text-sm text-gray-300 hover:text-white text-center"
            >
              Conditions
            </a>
            <a
              href="#"
              className="text-sm text-gray-300 hover:text-white text-center"
            >
              Confidentialité
            </a>
            <a
              href="#"
              className="text-sm text-gray-300 hover:text-white text-center"
            >
              Contact
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SalonPartnerLanding;
